body {
    background-color: var(--baseColor);
}
.container {
    width: 1100px;
    height: auto;
    margin: 0px auto;
}
section:last-child {
    padding-bottom: 140px;
}

.header {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 10000;
    width: 100%;
    height: 78px;
    background-color: var(--baseColor);
    border-bottom: 1px solid var(--mainColor);
    .container {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .logo {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 0;
            min-width: 70px;
            height: 100%;
            background-color: var(--baseColor);
            overflow: hidden;
            transition: width 0.5s ease 0.3s; /* width 변경 애니메이션, 지연 0.3초 */
            &.expand {
                width: 100%;
            }
            img {
                position: absolute;
                top: 20px;
                right: 0px;
                min-width: 70px;
                width: 70px;
                height: auto;
                object-fit: contain;
                transition: top 0.3s ease; /* top 변경 애니메이션 */
                &.move {
                    top: 50px;
                }
                &.reset {
                    top: 20px;
                }
            }
            p {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0;
                font-family: var(--fontG);
                font-weight: bold;
                color: var(--mainColor);
                transition: opacity 0.5s ease 0.3s; /* opacity 변경 애니메이션, 지연 0.3초 */
                &.visible {
                    opacity: 1;
                }
                &.hidden {
                    opacity: 0;
                }
            }
        }
        .header_menu {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding-left: 108px;        
            li {
                cursor: pointer;
                color: var(--subColor);
                transition: all 0.3s ease;
                a {
                    display: block;
                    padding: 10px;
                    font-size: 18px;
                }
                &.active {
                    color: var(--mainColor);
                }
                &:hover {
                    color: var(--mainColor);
                }
            }
        }
    }
}

.footer {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--baseColor);
    .inner_wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
        p {
            color: var(--mainColor);
            line-height: 1.2;
            text-align: right;
        }
    }
}
