:root {
    --fontG: 'gmarket';
    --fontN: 'nanumSquareNeo';
    --fontM: 'Montserrat';

    --mainColor: #9DBBFF;
    --mainColor_sec: #5085FF;
    --baseColor: #090E15;
    --baseColor_sec: #191e25;
    --baseColor_tr: #181E2A;
    --subColor: #7181A6;

    --fontColor1: #0C141E;
    --fontColor2: #090E15;
}